@import "../../shared/variables";

$max-width: 500px;

.error {
  background-color: $white;
  width: $max-width;

  box-shadow: 1px 1px 2px 1px hsla(225, 5%, 84%, 0.75),
  2px 2px 4px 2px hsla(225, 5%, 84%, 0.75),
  4px 4px 8px 4px hsla(225, 5%, 84%, 0.75),
  8px 8px 16px 8px hsla(225, 5%, 84%, 0.75);
}

.header {
  background-color: $primary;
  height: 40px;
}

.body {
  padding: 10px 15px 20px;

  h2 {
    color: $primary;
    font-weight: $font-weight-bold;
  }

  p {
    font-size: 0.9em;
    font-weight: $font-weight-bold;
  }
}
