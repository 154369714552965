@import "../../shared/variables";

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
        text-decoration-color: $primary;
    }
}

.link {
    color: $primary;
}

.background {
    background: url('../../assets/chevron-left.svg') no-repeat no-repeat;
    display: inline-block;
    width: 1em;
    height: 0.75em;
}

.passwordFieldWrapper {
    position: relative;
}

.submitContainer {
    display: flex;
    align-items: center;
}
