@import "../../shared/variables";

.label {
    display: block;
    font-family: $font-family;
    color: $text-grey-35;
    margin-bottom: 0.25em;
    margin-left: $border-radius;
}

.subtext {
    color: $text-grey-46;
    margin-bottom: 0.25em;
    display: block;
}

.errors {
    color: $text-red;
    display: block;
    font-size: 0.9em;
    height: 1em;
    margin: 0 0 0 $border-radius;
    padding: 0;
}
