@import "../../shared/variables";

.form {
    display: flex;
    flex-direction: column;

    padding: 3em;
    background-color: $white;

    > * + * {
        margin-top: 2em;
    }

    @media screen and (max-width: 375px) {
        padding: 2em;
    }
}
