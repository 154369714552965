@import "../../shared/variables";
.k-loader {
    display: flex;
}

.k-loader--center {
    justify-content: center;
}

@keyframes k-loader__double-ring {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes k-loader__double-ring {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes k-loader__double-ring_reverse {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

@-webkit-keyframes k-loader__double-ring_reverse {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

.k-loader__double-ring {
    position: relative;
}

.k-loader__double-ring div {
    position: absolute;
    width: 160px;
    height: 160px;
    top: 20px;
    left: 20px;
    border-radius: 50%;
    border: 8px solid;
    border-color: $primary transparent $primary transparent;
    -webkit-animation: k-loader__double-ring 2s linear infinite;
    animation: k-loader__double-ring 2s linear infinite;
}

.k-loader__double-ring div:nth-child(2) {
    width: 140px;
    height: 140px;
    top: 30px;
    left: 30px;
    border-color: transparent $text-grey-27 transparent $text-grey-27;
    -webkit-animation: k-loader__double-ring_reverse 2s linear infinite;
    animation: k-loader__double-ring_reverse 2s linear infinite;
}

.k-loader__double-ring {
    width: 50px !important;
    height: 50px !important;
    -webkit-transform: translate(-25px, -25px) scale(0.25) translate(25px, 25px);
    transform: translate(-25px, -25px) scale(0.25) translate(25px, 25px);
}
