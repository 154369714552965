// Colors
$primary: hsl(212, 74%, 38%); // #195cab - Contrast: 6.64:1
$primary-dark: hsl(212, 74%, 28%); // #13447c - Contrast: 9.78:1
$primary-darker: hsl(212, 74%, 18%); // #0c2c50 - Contrast: 14.07:1

$secondary: hsl(195, 43%, 59%); //  #69adc3 - Contrast: 2.51:1
$app-secondary: hsl(195, 43%, 51%); // #4C9DB8 - Contrast: 3.07:1
$text-secondary: hsl(195, 43%, 40%); // #3A7C92 - Contrast: 4.68:1

$tertiary: #796A9A;

$text-red: hsl(16, 75%, 46%); // #cd4c1d - Contrast: 4.51:1

// Status Colors
$success: hsl(94, 44%, 56%); //#8ac15f - Contrast: 2.12:1
$app-success: hsl(95, 44%, 44%); // #68A23F Contrast - 3.07:1
$text-success: hsl(95, 44%, 35%); // #538132 Contrast - 4.61:1

$white: #ffffff;

// Greys

/*
  Error header background
*/
$background: #f1f1f2;

/**
  Accessible GUI colors with a >3:1 contrast against a white background
  Accessible Large font (18.66px or larger and bold) or (typically 24px or larger) with a contrast of >3:1
 */
$app-grey-58: hsl(0, 0%, 58%); // #949494 - Contrast: 3.03:1
$app-grey-55: hsl(0, 0%, 55%); // #8d8d8d - Contrast: 3.31:1

/*
  Accessible colors with a >4.5:1 contrast against a white background
*/
$text-grey-46: hsl(0, 0%, 46%); // #767676 - Contrast: 4.54:1
$text-grey-35: hsl(0, 0%, 35%); // #595959 - Contrast: 7:1
$text-grey-27: hsl(0, 0%, 27%); // #444444 - Contrast: 9.73:1

// Font
$font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-weight-default: 400;
$font-weight-small: 300;

// Font Weight
$font-weight-default: 400;
$font-weight-bold: 500;

// Break Points
$break-point-small: 480px;
$break-point-medium: 768px;
$break-point-large: 991px;

// Borders
$border-radius: 0.5em;
$border-width: 1px;

// Focus
$outline: 2px solid $app-secondary;
