@import "../../shared/variables";


.link {
    box-sizing: border-box;
    border-radius: 0.25em;
    padding: 0.5em 1em;
    border: 1px solid $white;
    background-color: $primary;
    color: $white;
    text-decoration: none;
    font-size: 0.85em;
    line-height: 2.5em;

    &:hover {
        cursor: pointer;
        background-color: $primary-dark;
    }

    &:focus {
        outline: 2px solid $secondary;

    }

    &:active {
        background-color: $primary-darker;

    }
}
