@import '../../shared/variables';

.k-button {
  box-sizing: border-box;
  border-radius: 0.25em;
  padding: 0.5em 1em;
  border: none;
  font-size: inherit;
}

.k-button:hover {
  cursor: pointer;
}

.k-button:focus {
  outline: 2px solid $secondary;
}

.k-button--primary {
  background-color: $primary;
  color: $white;
}

.k-button--primary:hover {
  background-color: $primary-dark;
}

.k-button--primary:active {
  background-color: $primary-darker;
}

.k-button--secondary {
  background-color: $white;
  color: $text-grey-46;
  border: 1px solid $app-grey-58;
}

.k-button--secondary:hover {
  background-color: $primary;
  border-color: $primary;
  color: $white;
}

.k-button--secondary:active {
  background-color: $primary-dark;
  border-color: $primary-dark;
}

.k-button--tertiary {
  background-color: $tertiary;
  color: $white;
}

.k-button--tertiary:hover {
  background-color: darken($tertiary, 10%);
}

.k-button--tertiary:active {
  background-color: darken($tertiary, 20%);
}

.k-button--link {
  color: $primary;
  border: 1px solid $primary;
  background: $white;
  padding: 0.5em 2em;
}

.k-button--link:hover {
  background-color: $primary;
  color: $white;
  text-decoration: none;
}

.k-button--link:active {
  background-color: $primary-dark;
  border-color: $primary-dark;
}

.k-button--disabled {
  background-color: $app-grey-58;
  cursor: auto !important;
}

.k-button--disabled:hover {
  background-color: $app-grey-58;
}

.k-button--disabled:active {
  background-color: $app-grey-58;
}
