@import '../../shared/variables';

.p {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin-top: 0;
  margin-bottom: 30px;
  font-family: $font-family;
  font-size: inherit;
  color: $text-grey-46;
}

.span {
  background: #fff;
  padding: 0 10px;
}

.button {
  box-sizing: border-box;
  border-radius: 0.25em;
  margin: 0;
  padding: 0.5em 1em;
  border: 1px solid #000;
  font-size: inherit;
  background-color: white;
  color: $text-grey-46;
  text-align: center;
  text-decoration: none;
}

.button:hover {
  cursor: pointer;
  background-color: $primary;
  color: white;
}

.button:focus {
  outline: 2px solid $secondary;
}

.button:active {
  background-color: $primary-darker;
}

.footer {
  display: flex;
  align-items: center;
}
