@import '../../../shared/variables';

.container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.search i {
  position: absolute;
  font-size: 20px;
  padding: 2px;
}

.title {
  margin-bottom: 0;
  color: $primary;
}

.input {
  width: 80%;
  padding: 5px 5px;
  margin-top: 0;
  margin-bottom: 3em;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid black;
  text-indent: 20px;
}

.icon {
  padding: 10px;
  min-width: 40px;
}

.ssoFilter {
  overflow-y: auto;
  height: 100%;
}
