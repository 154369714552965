@import "./shared/variables";

.nav {
    background: $primary;
    display: flex;
    padding: 1em 2em;

    a {
        color: $white;
    }

    a + a {
        margin-left: 1em;
    }
}


.section {
    background: url("./assets/LoginBackground.svg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    padding-top: 7em;

    @media screen and (max-width: 899px){
        padding-top: 0;
    }

    // iPhone viewports for SE:320px and up to 375px
    @media screen and (max-device-width: 375px){
        display: flex;
        width: 100%;
    }
}




