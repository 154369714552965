@import '../../../shared/variables';

.link {
  text-decoration: none;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  margin: 0.5em;
  background-color: $white;
  border-radius: 0.25em;
  border: 1px solid black;
  background-size: cover;
  color: $primary;
  text-align: center;
  justify-items: center;

  &:hover {
    border: 3px solid black;
  }
}

.image {
  width: 50%;
}

.text {
  width: 50%;
}
