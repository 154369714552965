@import "../../shared/variables";

.details {
    display: flex;
    flex-direction: column;
    padding: 3em;
    background-color: $primary;
    color: $white;

    > * + * {
        margin-top: 3.5em;
    }

    @media screen and (max-width: 375px) {
        padding: 2em;
        justify-content: center;
    }
}

.header {
    h2 {
        margin: 0;
        font-weight: $font-weight-default;

    }

    p {
        margin: 0.5em 0 0;
        font-size: 0.85em;
    }
}

.footer {
    font-size: 0.75em;
    display: flex;
    flex: 1;

    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    ul {
        list-style: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        text-align: center;
        margin: 0.2em 0 0 0;
        padding: 0;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        @media screen and (max-width: 320px){
            display: block;
        }
    }

    li {
        padding: 0 0.5em;
    }

    li + li {
        border-left: 1px solid $white;

        @media screen and (max-width: 320px){
            border: none;
        }
    }

    li a {
        color: $white;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}
