@import "../../shared/variables";

$height: 53vh;
$max-width: 900px;

.main {
    margin: auto;
    max-width: $max-width;
    width: 900px;
    display: flex;

    box-shadow: 1px 1px 2px 1px hsla(225, 5%, 84%, 0.75),
    2px 2px 4px 2px hsla(225, 5%, 84%, 0.75),
    4px 4px 8px 4px hsla(225, 5%, 84%, 0.75),
    8px 8px 16px 8px hsla(225, 5%, 84%, 0.75);

    > * {
        width: 100%;
        height: 500px;
        flex-direction: column;
    }

    @media screen and (max-width: 899px) {
        width: auto;
        flex-direction: column;
        box-shadow: none;
        align-items: center;
    }

    @media screen and (max-width: 375px) {
        width: 100%;
        flex-direction: column;
        box-shadow: none;
        align-items: center;
    }
}

.centeredWithoutStyles {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    max-width: $max-width;
    height: $height;
}
