@import '../../shared/variables';

.k-input {
  font-weight: $font-weight-default;
  font-family: $font-family;
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
}

.k-input * + * {
  box-sizing: border-box;
}

.k-input__label {
  display: block;
  font-family: $font-family;
  color: $text-grey-35;
  margin-bottom: 0.25em;
  margin-left: $border-radius;
}

.k-input__subtext {
  color: $text-grey-46;
  margin-bottom: 0.25em;
  display: block;
}

.k-input__container {
  display: flex;
  margin-bottom: 0.25em;
  margin-left: 0;
}

.k-input__input {
  border: 1px solid $app-grey-58;
  border-radius: $border-radius;
  color: $text-grey-27;
  font-size: inherit;
  padding: 0.5em 0.75em;
  width: 100%;

  &::placeholder {
    color: $text-grey-46;
    font-size: inherit;
  }

  &:focus {
    border-color: $app-secondary;
    box-shadow: inset 0 0 0 1px $app-secondary;
    outline: none;
  }
}

.k-input__button {
  font-family: $font-family;
  padding: 0 0.5em;

  &:hover {
    cursor: pointer;
    border-color: $primary-dark;
    background-color: $primary-dark;
    color: $white;
  }

  &:focus {
    border-color: $app-secondary;
    box-shadow: inset 0 0 0 1px $app-secondary;
    outline: none;
  }

  &:active {
    border-color: $primary-darker;
    background-color: $primary-darker;
  }
}

.k-input__prepend {
  border: $border-width solid $app-grey-58;
  border-right: none;
  border-radius: $border-radius 0 0 $border-radius;
}

.k-input__append {
  border: $border-width solid $app-grey-58;
  border-left: none;
  border-radius: 0 $border-radius $border-radius 0;
}

.k-input__error {
  color: $text-red;
  display: block;
  font-size: 0.9em;
  height: 1em;
  margin: 0 0 0 $border-radius;
  padding: 0;
}

.k-input--errors {
  .k-input__input,
  .k-input__prepend,
  .k-input__append {
    border-color: $text-red;
    &:focus {
      box-shadow: inset 0 0 0 1px $text-red;
    }
  }
}

.k-input--prepend {
  .k-input__input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.k-input--append {
  .k-input__input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.kip-show {
  position: absolute;
  bottom: 21px;
  right: 6px;
  font-size: 30px;
}

.kip-hide {
  position: absolute;
  bottom: 21px;
  right: 6px;
  font-size: 30px;
}
