@import '../../../shared/variables';

.form {
  display: flex;
  flex-direction: column;

  padding: 1.5em;
  background-color: $white;

  > * + * {
    margin-top: 1.5em;
  }

  @media screen and (max-width: 375px) {
    padding: 2em;
  }
}

.link {
  color: $primary;
}

.background {
  background: url('../../../assets/chevron-left.svg') no-repeat no-repeat;
  display: inline-block;
  width: 1em;
  height: 0.75em;
}

.footer {
  display: flex;
  align-items: center;
}
